import React, { useEffect, useState } from 'react'
import Script from 'next/script'
import { Box, Center, useBreakpointValue } from '@chakra-ui/react'
import { GOOGLE_SIGNIN_ACCOUNT_ID } from '../general/constants'
declare const google
const GoogleSignInSignUp = ({ buttonText, handleLogin }) => {
  const [isGoogleSDKLoaded, setIsGoogleSDKLoaded] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })

  useEffect(() => {
    if (isGoogleSDKLoaded) {
      initGsiClient('googleSignInBtn', buttonText, handleLogin)
    }
  }, [isGoogleSDKLoaded])

  const initGsiClient = (btnId, text, callback = (googleUser) => {}) => {
    google?.accounts?.id?.initialize({
      client_id: GOOGLE_SIGNIN_ACCOUNT_ID,
      callback: callback,
    })
    google?.accounts?.id?.prompt()

    google.accounts.id.renderButton(document.getElementById(btnId), {
      width: isMobile ? '300px' : '800px',
      height: '80px',
      longtitle: true,
      size: 'large',
      text: text,
      theme: 'outline',
    })
  }

  return (
    <>
      <Center>
        <Box>
          <div
            id="googleSignInBtn"
            aria-label="Sign in with google"
            className="g-signin2 content"
            data-onsuccess="onSignIn"
            data-theme="dark"
          ></div>
        </Box>
      </Center>
      <Script
        async={true}
        id="google-client-script"
        src={'https://accounts.google.com/gsi/client'}
        onReady={() => setIsGoogleSDKLoaded(true)}
      ></Script>
    </>
  )
}

export default GoogleSignInSignUp
